import React, { Component, Fragment } from 'react';
import HttpStatus from 'http-status-codes';
import queryString from 'query-string';
import Login from './Login';
import Logout from './Logout';
import ErrorAlert from './ErrorAlert';
import TransparentBody from './TransparentBody';
import { checkLogin } from '../api';
import { wrap } from '../i18n';

const DOMAINS = ['ptmd.nl', 'daydreamhomedeco.nl', 'localhost'];

class App extends Component {
	state = {
		user: null,
		loggedIn: null,
		error: null,
		onLogin: null,
		onLogout: null
	};

	login = user => {
		this.setState({ user, loggedIn: true });
		const { onLogin } = this.state;
		if(onLogin) {
			this.redirect(onLogin, user);
		}
	}

	logout = () => {
		this.setState({ user: null, loggedIn: false });
		const { onLogout } = this.state;
		if(onLogout) {
			this.redirect(onLogout);
		}
	};

	redirect(url, data) {
		const form = document.createElement('form');
		form.method = 'post';
		form.action = url;
		document.body.appendChild(form);
		if(data) {
			Object.keys(data).forEach(key => {
				const input = document.createElement('input');
				input.type = 'hidden';
				input.value = data[key];
				input.name = key;
				form.appendChild(input);
			});
		}
		form.submit();
		document.body.removeChild(form);
	}

	componentDidMount() {
		const query = queryString.parse(window.location.search);
		this.setState({
			onLogin: this.checkRedirect(query.login),
			onLogout: this.checkRedirect(query.logout)
		});
		checkLogin(query.ott).then(user => {
			this.login(user);
		}).catch(err => {
			if(err.response && err.response.status === HttpStatus.UNAUTHORIZED) {
				this.logout();
			} else {
				this.setState({ error: err });
			}
		});
	}

	checkRedirect(url) {
		if(url) {
			if('URL' in window) {
				const u = new URL(url);
				if(DOMAINS.some(d => u.hostname.endsWith(d))) {
					return url;
				}
			} else if(DOMAINS.some(d => url.includes(d))) {
				return url;
			}
		}
		return null;
	}

	render() {
		const { t } = this.props;
		const { error, loggedIn, onLogin } = this.state;
		const query = queryString.parse(window.location.search);
		if(query.type === 'logoutButton') {
			if(!loggedIn) {
				return <TransparentBody />;
			}
			return <TransparentBody>
				<Logout logout={this.logout} noHeader />
			</TransparentBody>;
		}
		return <div className="container">
			<header className="mb-2 mt-2">
				<a href="https://ptmd.nl/" className="d-block mx-auto col-md-3 col-lg-2">
					<img alt="PTMD" src="/logo.png" className="mw-100" />
				</a>
			</header>
			<main className="p-3 bg-white rounded-lg">
				{error && <ErrorAlert error={error} />}
				{loggedIn !== null && <Fragment>
					{loggedIn ? <Logout logout={this.logout} /> : <Login login={this.login} redirect={onLogin} />}
				</Fragment>}
			</main>
			<footer className="text-right mt-2">
				<small>
					<a href="https://ptmd.nl/" className="text-muted">{t('Footer.Home')}</a>
				</small>
			</footer>
		</div>;
	}
}

export default wrap(App);
